import { createSelector } from "@reduxjs/toolkit";

import { RootReducerShape } from "../Storage";
import { ManagerActionState, NullableManagerAction } from "./ManagerActionStateSlice";

export const selectAction = (state: RootReducerShape): ManagerActionState => state.managerAction;

export const selectActiveAction = createSelector(selectAction, (managerAction) => managerAction.activeAction);
export const selectCallSid = createSelector(selectAction, (managerAction) => managerAction.callSid);
export const selectManagerActionLoading = createSelector(selectAction, (managerAction) => managerAction.loading);
export const selectManagerActionActiveTask = createSelector(selectAction, (managerAction) => managerAction.taskSid);
/**
 * @todo make it a proper selector with parameter.
 */
export const selectActiveTaskAction = (taskSid: string) =>
    createSelector(
        selectManagerActionActiveTask,
        selectActiveAction,
        (activeTaskSid: string | null, activeAction: NullableManagerAction) =>
            taskSid === activeTaskSid ? activeAction : "disabled"
    );
