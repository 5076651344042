import { useContext } from "react";

import { UserContext } from "App/contexts";
import { formatToE164 } from "Services/CommunicationService";

import type { PhoneEntry } from "Types/Campaign";

export function useAgentPhoneNumberDetails(regalVoicePhone: string | undefined): PhoneEntry | undefined {
    const { brand } = useContext(UserContext);

    if (!regalVoicePhone) {
        return undefined;
    }

    const details = brand?.communicationSources?.find((communicationSource) => {
        return (
            communicationSource?.source && formatToE164(communicationSource.source) === formatToE164(regalVoicePhone)
        );
    });

    if (details) {
        return details;
    }

    return { source: regalVoicePhone };
}
