import { useState, useEffect } from "react";

import { Modal, notification } from "antd";
import { interval, BehaviorSubject } from "rxjs";
import { distinctUntilChanged, startWith, switchMap } from "rxjs/operators";

import { getAudioPermissions } from "Services/AudioPermissionsService";
import { useFlags } from "Services/FeatureFlagService";
import { getLogger } from "Services/LoggingService";
import { setAgentOffline } from "Services/state/agent/AgentInformationThunks";
import { useRVDispatch } from "Services/state/Storage";

// assume we have the permission until we know otherwise

const MIC_ASSUMPTION = true;
export const hasMicPerms$ = new BehaviorSubject<boolean>(MIC_ASSUMPTION);

// check permissions every 5 seconds
interval(5000)
    .pipe(
        startWith(0), // Emit at start
        switchMap(getAudioPermissions), // Do the check
        distinctUntilChanged() // Filter if it hasn't changed
    )
    .subscribe((perm) => hasMicPerms$.next(perm));

const MIC_PERMISSIONS_LINK =
    "https://support.regalvoice.com/hc/en-us/articles/4466197655579-How-To-Set-Up-My-Device-to-Use-Regal-Voice";

const DISPLAY = {
    modal: "modal",
    notification: "notification",
    hide: "hide",
};

const PERMISSIONS_REQD_TEXT = (
    <>
        Regal Voice requires your browser’s microphone to work correctly.
        <br />
        For more information, including how to change this setting, click{" "}
        <a href={MIC_PERMISSIONS_LINK} target="_blank" style={{ color: "var(--blue-3)" }} rel="noreferrer">
            here
        </a>
        .
    </>
);

const logger = getLogger("MicPermissionsCheck");

function showNotification(hasPermissions: boolean, enforceMicPermissions: string) {
    if (hasPermissions || enforceMicPermissions != DISPLAY.notification) {
        logger.debug("Notification display: Permission granted or display type changed, hiding");
        notification.close("mic-perms");
    } else if (enforceMicPermissions == DISPLAY.notification) {
        logger.debug("Notification display: Needs permission");
        notification.error({
            message: "Microphone access required",
            description: PERMISSIONS_REQD_TEXT,
            duration: 0,
            key: "mic-perms",
            onClose: () => setTimeout(showNotification, 1000),
        });
    }
}

export function MicPermissionsCheck(): JSX.Element | null {
    const { enforceMicPermissions } = useFlags();
    const dispatch = useRVDispatch();
    // Start with the assumption that we have permission
    const [hasPermissions, setHasPermissions] = useState<boolean>(MIC_ASSUMPTION);

    useEffect(() => {
        hasMicPerms$.subscribe((newPermission) => {
            logger.log("Mic permission change", { newPermission });
            setHasPermissions(newPermission);
        });
    }, []);

    useEffect(() => {
        showNotification(hasPermissions, enforceMicPermissions);
        if (!hasPermissions && enforceMicPermissions == DISPLAY.modal) {
            dispatch(setAgentOffline());
        }
    }, [hasPermissions, enforceMicPermissions, dispatch]);

    if (enforceMicPermissions == DISPLAY.modal) {
        return (
            <Modal open={!hasPermissions} closable={false} footer={null}>
                <div data-testid="mic-permissions-modal">{PERMISSIONS_REQD_TEXT}</div>
            </Modal>
        );
    }

    return null;
}
