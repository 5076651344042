const checkBrowserPermission = async (): Promise<boolean> => {
    try {
        const { state } = await navigator.permissions.query({ name: "microphone" as PermissionName });
        return state === "granted";
    } catch (ignore) {
        return false;
    }
};

const checkSystemPermission = async (): Promise<boolean> => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        stream.getAudioTracks().forEach((media) => media.stop());
        return true;
    } catch (ignore) {
        return false;
    }
};

export const audioPermissionsOptions = {
    systemCheckDone: false,
};

export async function getAudioPermissions(): Promise<boolean> {
    const promises: Promise<boolean>[] = [checkBrowserPermission()];
    if (!audioPermissionsOptions.systemCheckDone) {
        promises.push(checkSystemPermission());
        audioPermissionsOptions.systemCheckDone = true;
    }

    const micPermissions = await Promise.all(promises);
    return micPermissions.every((permission) => permission);
}
